.table thead {
  font-size: 0.9rem;
}

.table > :not(caption) > * > * {
  background-color: transparent;
  color: inherit;
}

.table th {
  color: var(--color-font);
  background-color: var(--bg-color);
}

.darkBgCell {
  background-color: var(--bg-color) !important;
}

.icon {
  top: -1px;
}

.icon svg {
  width: 15px;
}

.repoCell {
  max-width: 300px;
}

.repoBtn {
  max-width: 100%;
}

.tooltipMessage {
  margin-left: -8px;
}

@media only screen and (max-width: 767.98px) {
  .table {
    width: 100%;
    table-layout: fixed;
    overflow-wrap: break-word;
  }

  .table th:first-child {
    width: 65%;
  }

  .table th {
    min-width: 100px !important;
  }
}

@media only screen and (min-width: 768px) {
  .table th {
    min-width: 90px;
  }

  .table th:first-child {
    width: 100%;
  }

  .table thead {
    font-size: 0.8rem;
  }

  .repoBtn {
    font-size: 0.9rem;
  }

  .repoCell {
    max-width: 185px;
  }
}

@media only screen and (min-width: 1200px) {
  .table thead {
    font-size: 0.9rem;
  }

  .repoBtn {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1400px) {
  .table th {
    min-width: 150px;
  }
}
