.categoryTitle {
  margin-top: 2rem;
}

.decorator {
  top: 3px;
}

label {
  font-size: 0.9rem;
}
