.boxWrapper {
  margin: 0 -1rem;
}

.progress {
  height: 0.35rem;
  background-color: var(--bg-progressbar-stats) !important;
}

.scoreWrapper {
  padding-right: 0.85rem;
}

.table {
  max-width: 100%;
}

.extIcon {
  font-size: 0.65rem;
  margin-top: -1px;
}

.headerAnchor {
  top: -1.5rem;
}

.linksList {
  font-size: 0.8rem;
}

.table {
  background-color: var(--body-bg);
}

.table > :not(caption) > * > * {
  background-color: transparent;
  color: inherit;
}

.reportContent {
  background-color: var(--bg-color);
}

.progressbar {
  animation: all 0.6s ease;
}

@media only screen and (min-width: 576px) {
  .progressbarWrapper {
    max-width: 50%;
  }
}

@media only screen and (min-width: 768px) {
  .headerAnchor {
    top: -115px;
  }
}

@media only screen and (min-width: 992px) {
  .table {
    max-width: 1300px;
  }
}

@media (hover: hover) {
  .titleWrapper:hover .headingLink {
    opacity: 1;
  }
}
