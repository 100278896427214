.wrapper {
  margin: 0.1rem 0;
}

.title {
  font-size: 75%;
  min-width: 120px;
  width: 45%;
  max-width: 230px;
}

.icon {
  top: -1px;
}

.value {
  min-width: 35px;
  font-size: 0.75rem;
  background-color: var(--color-black-10);
}

.progressWrapper {
  height: 4px;
  background-color: var(--color-black-10);
  margin-top: 7px;
}

.progressBigWrapper {
  margin-top: 10px;
}

.line {
  transition: all 0.6s ease;
}

.arrow {
  width: 0px;
  height: 0px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  right: -2px;
}

.btn {
  font-size: 0.75rem;
}

@media only screen and (min-width: 768px) {
  .bigSize {
    height: 24px;
    line-height: 24px;
    font-size: 0.8rem;
    width: 50px;
  }

  .bigArrow {
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
  }
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 65%;
  }
}
