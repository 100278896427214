.wrapper {
  margin: 0.75rem 0;
}

.progressWrapper,
.btn {
  font-size: 0.85rem;
  color: var(--color-font);
}

.progressTitle {
  min-width: 185px;
}

.progress {
  height: 0.35rem;
  background-color: var(--bg-progressbar-stats) !important;
}

.icon {
  margin-top: -2px;
}

.icon > svg {
  width: 15px;
  height: 15px;
}

.scoreWrapper {
  width: 55px;
}

.progressbar {
  animation: all 0.6s ease;
}

@media only screen and (max-width: 575.98px) {
  .icon {
    font-size: 0.65rem;
  }

  .progressTitle {
    min-width: 145px;
    font-size: 0.75rem;
  }

  .scoreWrapper {
    width: 45px;
    font-size: 0.7rem;
  }
}
