.content {
  background-color: var(--bg-color);
}

.card {
  cursor: pointer;
}

.imageWrapper {
  height: 75px;
  width: 75px;
  min-width: 75px;
  font-size: 3rem;
}

.miniImageWrapper {
  height: 40px;
  width: 40px;
  min-width: 40px;
  font-size: 2rem;
}

.imageWrapper img,
.miniImageWrapper img {
  max-height: 100%;
  max-width: 100%;
}

.title {
  font-size: 1.3rem;
  line-height: 1.2;
}

.foundationBadge {
  top: -2px;
}

.subtitle {
  font-size: 0.8rem;
}

.statsIcon {
  width: 20px;
  height: 20px;
}

.calendarIcon {
  width: 18px;
  height: 18px;
}

.wrapperCalendar:hover .calendarIcon,
.link:hover {
  color: var(--clo-tertiary) !important;
}

.legend {
  font-size: 0.75rem;
}

.statsIcon path {
  stroke: inherit;
}

.description {
  height: 43px;
  font-size: 0.9rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.global {
  height: 2.5rem !important;
  width: 2.5rem !important;
  min-width: 2.5rem !important;
  font-size: 1rem !important;
}

.tooltipMessage {
  margin-left: 10px;
}

.tooltipArrow {
  margin-top: 7px;
}

@media only screen and (max-width: 575.98px) {
  .description {
    -webkit-line-clamp: 4;
    max-height: 86px;
    height: auto;
  }

  .title {
    font-size: 1rem;
  }

  .description {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 1199.98px) {
  .title {
    font-size: 1.15rem;
  }
}

@media only screen and (max-width: 767.98px) {
  .title {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 1400px) {
  .cardWrapper {
    padding: 1.3rem;
  }

  .link,
  .subtitle {
    font-size: 0.9rem;
  }
}

@media (hover: hover) {
  .card:hover {
    border-color: var(--clo-primary);
    box-shadow: 0px 0px 5px 0px var(--clo-primary-50);
  }
}

@media only screen and (min-width: 768px) {
  .global {
    height: 3rem !important;
    width: 3rem !important;
    min-width: 3rem !important;
    font-size: 1.25rem !important;
  }
}
