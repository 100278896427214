.summary {
  background-color: var(--bg-color);
  font-size: 1rem;
  padding: 1rem;
}

.badge {
  width: 3.5rem;
  border: 2px solid transparent;
}

@media only screen and (min-width: 768px) {
  .bigSize {
    padding: 2rem 1.5rem;
  }

  .summary:not(.bigSize) {
    padding: 1.5rem;
  }

  .badge {
    width: 5.5rem;
  }
}
