main {
  margin-top: 0.25rem;
}

.sidebar {
  width: 200px;
  min-width: 200px;
  background-color: var(--bg-color);
  margin-top: 2rem;
  margin-right: 1.5rem;
}

.spinner {
  color: var(--bg-color) !important;
}

.spinner:before,
.spinner:after {
  border-color: var(--bg-color);
}

.btnMobileFilters {
  height: 2rem;
  width: 2rem;
}

.closeSidebar {
  min-width: 135px;
}

.searchResults {
  font-size: 0.9rem;
}

.list {
  margin-top: -0.5rem;
}

.contentWrapper {
  max-width: calc(100% - 200px - 1.5rem);
}

.loading {
  top: 144px !important;
}

.loadingWithFilters {
  top: 183px !important;
}

@media only screen and (min-width: 1400px) {
  .sidebar {
    width: 250px;
    min-width: 250px;
    margin-right: 1.3rem;
    margin-top: 3rem;
  }

  .contentWrapper {
    max-width: calc(100% - 250px - 1.3rem);
  }

  .list {
    margin-right: -1.3rem;
    margin-top: 0.7rem;
  }
}

@media only screen and (max-width: 991.98px) {
  .loading {
    top: 128px !important;
  }

  .loadingWithFilters {
    top: 167px !important;
  }
}

@media only screen and (max-width: 767.98px) {
  .contentWrapper {
    width: 100%;
    max-width: 100%;
  }

  .loading,
  .loadingWithFilters {
    top: 160px !important;
  }
}

@media only screen and (max-width: 575.98px) {
  .loading,
  .loadingWithFilters {
    top: 207px !important;
  }

  .extraMargin {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
}
