.contentWrapper {
  width: calc(100% - 70px);
}

.imageWrapper {
  height: 90px;
  width: 90px;
  min-width: 90px;
  font-size: 3rem;
}

.imageWrapper img {
  max-height: 100%;
  max-width: 100%;
}

.title {
  font-size: 1.3rem;
  line-height: 1.2;
}

.legend {
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.link,
.subtitle {
  font-size: 0.8rem;
}

.statsIcon path {
  stroke: inherit;
}

.global {
  height: 4rem;
  width: 4rem;
  min-width: 4rem;
  font-size: 1.5rem;
}

.updated {
  font-size: 0.75rem;
}

.loadingWithBackBar {
  top: 139px !important;
}

.loading {
  top: 93px;
}

@media only screen and (max-width: 575.98px) {
  .contentWrapper {
    width: 100%;
  }

  .loadingWithBackBar {
    top: 151px !important;
  }

  .loading {
    top: 112px;
  }

  .title {
    font-size: 1.1rem;
  }

  .imageWrapper {
    height: 40px;
    width: 40px;
    min-width: 40px;
    font-size: 2rem;
  }

  .titleWrapper {
    background-color: var(--bg-color);
  }

  .global {
    height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    font-size: 1rem;
  }

  .description {
    font-size: 0.8rem;
  }

  .backBtn {
    font-size: 0.9rem;
  }

  .extraMarginB {
    margin-bottom: 4rem !important;
  }
}

@media only screen and (min-width: 1400px) {
  .link,
  .subtitle {
    font-size: 0.9rem;
  }
}
