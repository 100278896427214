.headerWrapper {
  background-color: var(--body-bg) !important;
}

.global {
  height: 2rem;
  width: 2rem;
  min-width: 2rem;
  font-size: 0.8rem;
  border-width: 0.2rem;
}

.headerAnchor {
  top: -90px;
}

.headingLink {
  width: 35px;
  opacity: 0;
  padding-top: 6px;
  padding-bottom: 2px;
}

.headingLink svg {
  width: 15px;
  height: 15px;
}

.repoName {
  font-size: 1.25rem;
}

.tooltipMessage {
  margin-left: -3px;
}

.alert {
  font-size: 0.9rem;
}

.error {
  background-color: var(--bg-code);
  color: var(--color-font);
  width: 100%;
}

.checkSetBadge {
  margin-top: 3px;
}

@media (hover: hover) {
  .titleWrapper:hover .headingLink {
    opacity: 1;
  }
}

@media only screen and (max-width: 767.98px) {
  .headingLink {
    margin-top: -3px;
  }

  .headingLink svg {
    width: 13px;
    height: 13px;
  }

  .headerAnchor {
    top: -0.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .global {
    height: 4rem;
    width: 4rem;
    min-width: 4rem;
    font-size: 1.5rem;
    border-width: 0.25rem;
  }
}

@media only screen and (max-width: 575.98px) {
  .headingLink {
    opacity: 1;
  }

  .repoName {
    font-size: 1rem;
  }
}
