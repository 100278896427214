.navbar {
  background-color: var(--clo-secondary);
}

.logo {
  height: 25px;
}

.link {
  letter-spacing: 2px;
}

@media (hover: hover) {
  .link:hover::before {
    content: '';
    position: absolute;
    top: -28px;
    left: 0;
    right: 0;
    height: 4px;
    background-color: var(--bs-white);
    opacity: 0.5;
  }
}

@media only screen and (max-width: 575.98px) {
  .searchWrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 767.98px) {
  .line {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .navbar {
    position: fixed !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1040;
  }

  :global(.noScroll-modal) .navbar,
  :global(.noScroll-sidebar) .navbar {
    position: relative !important;
    z-index: 1;
  }
}
