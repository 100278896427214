.name {
  font-size: 0.85rem;
}

.icon {
  font-size: 1.25rem;
}

.iconCheck path {
  stroke: inherit;
}

.exemptIcon {
  font-size: 1.35rem;
}

.failedIcon {
  font-size: 1.5rem;
  color: var(--bs-orange);
}

.dot {
  top: 0;
  right: 5px;
  height: 10px;
  width: 10px;
  border: 2px solid var(--body-bg);
}

.reason {
  font-size: 0.8rem;
}

.failedReason {
  white-space: pre-line;
}

.iconCell {
  width: 40px;
}

.legend {
  font-size: 75%;
  margin-bottom: 2px;
}

.contentCell {
  table-layout: fixed;
  overflow-wrap: break-word;
}

.tooltipMessage {
  margin-left: -3px;
}

.reasonTooltipMessage {
  margin-left: 5px;
}

.reasonTooltipArrow {
  margin-top: 7px;
}

.miniIcon {
  font-size: 0.75rem;
}

.extraMiniIcon {
  font-size: 0.6rem;
  margin-bottom: -1px;
  padding-right: 1px;
}

.infoIcon {
  font-size: 0.75rem;
  margin-top: -4px;
}

.detailsDropdown {
  left: 0px;
}

.detailsWrapper {
  max-height: 270px;
  white-space: pre-line;
}

.detailsContent {
  font-size: 0.8rem;
}

.detailsContent p {
  margin-bottom: 0px;
}

.codeContent {
  white-space: pre;
}

.btn {
  font-size: inherit;
}

.iframeWrapper {
  max-width: 1000px;
}

.iframe {
  margin: 4rem 0;
  height: calc(100% - 8rem);
}

/* Scroll */
.visibleScroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.visibleScroll::-webkit-scrollbar-thumb,
.visibleScroll::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  background-color: #686868;
  border: 2px solid var(--bs-white);
}

.visibleScroll::-webkit-scrollbar-corner {
  background-color: transparent;
}

@media only screen and (min-width: 576px) {
  .name {
    font-size: 1rem;
  }

  .iconCell {
    width: 60px;
  }
}
