.link {
  font-size: 0.8rem;
  max-width: 100%;
  color: var(--color-font);
}

.icon {
  min-width: 15px;
}

.onlyIcon .icon {
  height: 18px;
  width: 18px;
}

.onlyIcon:hover {
  color: var(--clo-tertiary) !important;
}

.miniIcon {
  min-width: 15px;
  top: 1px;
  font-size: 0.9rem;
}

.linkName {
  max-width: 220px;
}

@media only screen and (min-width: 1400px) {
  .link {
    font-size: 0.9rem;
  }
}
