.btn {
  height: 24px;
  width: 24px;
  color: var(--color-black-25);
  background-color: var(--bg-progressbar-stats);
  border-color: var(--color-black-25) !important;
}

.passingBtn {
  margin-right: 0.35rem;
}

.isPassing,
.isPassing:hover {
  background-color: var(--clo-green);
  color: #fff;
}

.isNotPassing,
.isNotPassing:hover {
  background-color: var(--clo-red);
  color: #fff;
}

.iconTooltipArrow {
  left: 5px !important;
}

.btns {
  min-width: 50px;
}

.label {
  margin-top: 2px;
}

@media only screen and (max-width: 575.98px) {
  .label {
    font-size: 0.8rem;
  }

  .btn {
    height: 20px;
    width: 20px;
  }
}
