.quality {
  height: 1rem;
  width: 1rem;
  min-width: 1rem;
  border-color: var(--color-black-25) !important;
}

.level1 {
  background-color: var(--clo-green);
}

.level2 {
  background-color: var(--clo-yellow);
}

.level3 {
  background-color: var(--clo-orange);
}

.level4 {
  background-color: var(--clo-red);
}
