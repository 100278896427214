.label {
  font-size: 0.875rem;
}

.imgWrapper {
  min-height: 237px;
}

.imgWrapper img {
  max-width: 100%;
}
