.loading {
  position: fixed;
}

.withContent {
  top: 50%;
  margin-top: 9px;
}

.loadingWrapper {
  top: 115px !important;
}

.chartWrapper {
  background-color: var(--bs-white) !important;
}

.reducedPaddingBottom {
  padding-bottom: calc(1rem - 15px) !important;
}

.cardHeader {
  font-size: 0.85rem;
}

.title {
  font-size: 1.5rem;
}

.select {
  width: 210px;
}

.checksBody {
  padding: 1.5rem 2rem;
}

.statsWrapper {
  width: calc(100% - 44px - 3rem);
}

.timeline {
  margin-top: 124px;
}

.dot1,
.dot2,
.dot3 {
  background: var(--clo-primary);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 6px;
}

.dot1 {
  animation: jump 1.2s -0.32s linear infinite;
}

.dot2 {
  animation: jump 1.2s -0.16s linear infinite;
}

.dot3 {
  animation: jump 1.2s linear infinite;
}

.miniSpinner {
  top: 2px;
}

@keyframes jump {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

[data-theme='dark'] :global(.card).chartWrapper {
  background-color: var(--color-black-5) !important;
}

:global(.apexcharts-heatmap-rect) {
  stroke: var(--bs-white);
}

[data-theme='dark'] :global(.apexcharts-heatmap-rect) {
  stroke: var(--bg-color);
}

:global(.apexcharts-tooltip) {
  background-color: var(--bs-white) !important;
  border-color: var(--clo-secondary-900) !important;
  color: var(--font-color) !important;
}

:global(.apexcharts-tooltip.apexcharts-theme-dark) {
  background-color: var(--bs-white) !important;
  border: 1px solid var(--solid-border) !important;
  color: var(--font-color) !important;
}

:global(.apexcharts-tooltip-title) {
  background-color: var(--color-black-5) !important;
  border-color: var(--clo-secondary-900) !important;
}

:global(.apexcharts-xaxistooltip) {
  background-color: var(--bs-white) !important;
  color: var(--font-color) !important;
  border-color: var(--clo-secondary-900) !important;
}

:global(.apexcharts-xaxistooltip)::before,
:global(.apexcharts-xaxistooltip)::after {
  border-bottom-color: var(--clo-secondary-900) !important;
}

:global(.apexcharts-canvas) {
  border-radius: 0 !important;
}

.donutWrapper :global(.apexcharts-series.apexcharts-pie-series),
.chartWrapper :global(.apexcharts-heatmap-rect):not([val='10']) {
  cursor: pointer;
}

.downloadBtn {
  font-size: 90%;
}

.downloadIcon {
  top: -2px;
  height: 12px;
}

.downloadIcon path {
  stroke: var(--color-font);
}

.downloadSpinner {
  right: -30px;
}

@media only screen and (max-width: 991.98px) {
  .statsWrapper {
    width: calc(100% - 44px - 1.5rem);
  }
}

@media only screen and (max-width: 575.98px) {
  .statsWrapper {
    width: 100%;
  }

  .loadingWrapper {
    top: 172px !important;
  }

  .donutWrapper {
    padding: 1rem 0.15rem;
  }

  .donutWrapper :global(.apexcharts-legend-series) {
    margin: 2px 3px;
  }

  .checksBody {
    padding: 1rem;
  }

  .selectWrapper {
    width: 100%;
  }

  .select {
    width: 100%;
  }

  .withContent {
    margin-top: 24px;
  }

  .title {
    font-size: 1.25rem;
  }

  .subtitle {
    font-size: 0.9rem;
  }

  .downloadBtn {
    font-size: 80%;
  }

  .downloadIcon {
    height: 11px;
    top: -2px;
  }
}
