.footer {
  background-color: var(--clo-secondary-900);
}

.logo {
  height: 30px;
}

.miniIcon {
  font-size: 0.75rem;
}

.footerCol {
  padding-right: 5rem;
}

.invisibleFooter {
  opacity: 0;
}

@media only screen and (max-width: 991.98px) {
  .hexagon {
    font-size: 3rem;
    width: 100%;
  }

  .footerCol,
  .fullMobileSection {
    padding: 0 1rem;
  }

  .footerCol {
    padding-bottom: 1.5rem;
  }

  .fullMobileSection {
    width: 100%;
    text-align: center;
  }
}

@media only screen and (min-width: 576px) {
  .license {
    display: flex;
    flex-direction: column;
  }
}
